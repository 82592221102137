/**
 * Gathering all the mobile behaviours
 */


var gsap = require('gsap');

module.exports = function ($, window, document, Drupal) {

  function pioneersDropdown() {

    if ($('.view-Pinoiere').length > 0) {

      var a = $('.thema-region_top').find('.field-name-field-thema-lead');
      var b = $('.thema-region_top').find('.medium-4');
      var c = $('.thema-region_bottom');
      //trigger dropdown
      var d = $('.thema-region_top');

      a.css("display", "none");
      b.css("display", "none");
      c.css("display", "none");

      var btn_more = "More";
	  
      var url = $(location).attr('href').split('/')[3];
          switch (url) {
            case "de":
              btn_more = "Mehr";
              break;
            case "fr":
              btn_more = "Plus";
              break;
            case "it":
              btn_more = "Di Più";
              break;
          }
  
      d.append("<a class='more-btn'>" + btn_more + "</a>");
  

      var e = false;

      d.on('click', function () {
        if (e != true) {
          $(this).find('h2').addClass("dropdown-active");
          $(this).find('.field-name-field-thema-lead').css("display", "block");
          $(this).find('.medium-4').css("display", "block");
          $(this).find('.more-btn').css("display", "none");
          $(this).next('.thema-region_bottom').css("display", "block");
          return e = true;
        } else {
          $(this).find('h2').removeClass("dropdown-active");
          $(this).find('.field-name-field-thema-lead').css("display", "none");
          $(this).find('.medium-4').css("display", "none");
          $(this).find('.more-btn').css("display", "block");
          $(this).next('.thema-region_bottom').css("display", "none");
          return e = false;
        }
      });

    }
  }

  pioneersDropdown();

}(jQuery, this, document, window, Drupal);
