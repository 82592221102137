var bowser = require('bowser');
var $ = require('jquery');

//Init Drupal dependencies
require('./drupal/drupal');


require('sticky-kit/dist/sticky-kit.js');

//Init custom dependencies
$('document').ready(function () {

  if(bowser.tablet || bowser.mobile ) require('./shared/mobile');

  require('./shared/desktop');


  //Handling the mobile menu opening
  function openMenu() {
    var menuIcn = $('#off-canvas-open');
    var menuContainer = $('#menu-section');
    var body = $('body');

    menuIcn.on('click', function () {
      if ($(this).hasClass('off-canvas-open__close-active')) {
        var _this = $(this);
        setTimeout(function () {
          _this.removeClass('off-canvas-open__close-active');
          body.removeClass('mobile-menu__isopened');
          menuContainer.removeClass('menu-section__isopened');
        }, 50)
      } else {
        body.addClass('mobile-menu__isopened');
        $(this).addClass('off-canvas-open__close-active');
        menuContainer.addClass('menu-section__isopened');
      }
    });

  }

  openMenu();

  //We handle the sticky effect for the social sharing links only on tablet and desktop
  if (bowser.tablet || !bowser.mobile) {
    var a = document.querySelector('.sticky-social-btns');
    jQuery('.sticky-social-btns').stick_in_parent({
      offset_top: 5,
    });
  }

  //We handle the language switcher on tablets
  if (!bowser.mobile) {
    languageTabletSwitcher()
  }
  function languageTabletSwitcher() {
    $('.language-switcher-locale-url > .active > a').on('click', function (e) {
      e.preventDefault();
      $('.language-switcher-locale-url').addClass('language-switcher-is_active');
    });
  }

  //We add a fake padding to the paging el to simulate a centered effect
  function centerPagniation() {
    var a = document.querySelector('.pager-previous');
    var b = document.querySelector('.pager-next');
    if (a === null) {
      $('.pager').css("padding-left", "13%");
    } else if (b === null) {
      $('.pager').css("padding-right", "14%");
    }
  }

  centerPagniation();


  //we put a limitation on the length of the node-milenstein-title-bottom
  function cropTitleNodeMilestoneBottom() {
    var a = $('.pionierdetail-bottom .field-name-meilenstein-titel-anzeige h2');
    for (var i = 0; i < a.length; i++) {
      let b = a[i].innerHTML.substr(0, (a[i].innerHTML.length - 3));
      if (a[i].innerHTML.length > 56) {
        a[i].innerHTML = b + ' ...';
      }
    }
  }

  cropTitleNodeMilestoneBottom();


});
