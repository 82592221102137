
module.exports =  function ($, window, document, Drupal, Lightbox) {

  //TODO: 1. ist noch nicht responsive 2. position stimmt noch nicht genau

  $(document).ready(function () {
    // Target your .container, .wrapper, .post, etc.
    $('body').fitVids();

    //Lightbox for Project
    var images = $('<div class="image-wrapper"><div class="field-items"></div></div>');
    var wrapper = $('.node-pionier .field-name-field-pionier-images').last();
    Drupal.imageLightbox(images, wrapper);
    wrapper.html(images);

    //Lightbox for Entity Paragraphs
    var images = $('<div class="image-wrapper"><div class="field-items"></div></div>');
    var wrapper = $('.entity-paragraphs-item .field-name-field-text-image').last();
    Drupal.imageLightbox(images, wrapper);
    wrapper.html(images);

    $('.mailchimp-signup-subscribe-form .slider.round').bind('click', function () {
      $('.form-item-mailchimp-lists-interest-groups-28c3b1d98b').toggleClass('show');
    });

  });

  Drupal.fixFooter = function () {
    $('img').imagesLoaded(function () {
      var footer = $("#footer_wrapper");
      var pos = footer.position();
      var height = $(window).height();
      height = height - pos.top;
      height = height - footer.height() + 30;
      if (height > 0) {
        footer.css({
          'margin-top': height + 'px'
        });
      }
    });
  }

  Drupal.imageLightbox = function (images, wrapper) {
    wrapper.find('.field-item').each(function (k, image) {
      var featured = (k === 0) ? '<div class="featured-img-foreground"><div class="featured-img-zoom"></div></div>' : '';
      var caption = $(image).find('img').attr('title') ? $(image).find('img').attr('title') : '';

      images.find('.field-items').append(
        '<div class="field-item"><a href="' +
        $(image).find('img').attr('src').replace('styles/meilenstein_thumb/public/', '') +
        '" class="lightbox-proceed" rel="lightbox[item-' +
        wrapper.attr('data-nid') + ']" title="' +
        caption + '">' + featured + $(image).find('img')[0].outerHTML +
        '<span class="caption">' + caption + '</span></a></div>');
    });

    $(document).on('click', 'a.lightbox-proceed', function (e) {
      e.preventDefault();
      Lightbox.start(this, false, false, false, false);
      return false;
    });

    if (wrapper.find('.field-item').length > 1) {
      images.prepend('<div class="gallerie-count"><div class="gallerie-count-number">' + wrapper.find('.field-item').length + '</div></div>');
    }
  }

  Drupal.behaviors.fixedFooter = {
    attach: function (context, settings) {
      $('#footer', context).once('fixedFooter', function () {
        Drupal.fixFooter();
      });
    }
  };

  Drupal.behaviors.paragraphFolding = {
    attach: function attach(context, settings) {

      $('.expand-toggle', context).once('paragraphFolding', function () {
        $(this).bind('click', function(e) {
          $('.expand').toggleClass('open');
          $('.gradient').toggleClass('opened');
          $(this).toggleClass('opened');
          });
        });
      }
  };

  Drupal.behaviors.MailchimpForm = {
    attach: function (context, settings) {
      $('form.mailchimp-signup-subscribe-form .form-item', context).once('MailchimpForm', function () {
        $(this).each(function () {
          if ($(this).find('input.form-checkbox').length != 0) {
            //alert('form-checkbox');
          } else {
            $(this).find('input.form-text').attr('placeholder', $(this).find('label').text());
          }
        });
      });
    }
  };

  // /* Drupal.offCanvasWindowResize = function () {
  //      if (!$('#off-canvas-wrap').hasClass('show-nav')) {
  //          var menuSection = $('#off-canvas-wrap #menu-section');
  //          var windowWidth = $(window).width();
  //          menuSection.css('width', windowWidth + 'px');
  //          menuSection.css('right', '-' + windowWidth + 'px');
  //          // menuSection.css('height', '100px');
  //      }
  //  };
  //
  //  Drupal.menuSectionResize = function () {
  //      if (!$('#off-canvas-wrap').hasClass('show-nav')) {
  //          var menuSection = $('#menu-section');
  //          var heightDiff = $('#off-canvas-wrap').height() - menuSection.height();
  //          menuSection.css('top', '-' + heightDiff + 'px');
  //      }
  //  };*/

  Drupal.translateX = function (selector, x) {
    var translate = 'translateX(' + x + ')';
    selector.css('-moz-transform', translate);
    selector.css('-ms-transform', translate);
    selector.css('-webkit-transform', translate);
    selector.css('transform', translate);
  };






  // Drupal.behaviors.offCanvas = {
  //     attach: function (context, settings) {
  //         $('#off-canvas-wrap', context).once('offCanvas', function () {
  //             var mainSection = $('#off-canvas-wrap #main-section');
  //             var offCanvasSection = $('#off-canvas-wrap');
  //
  //             $('#off-canvas-close,#off-canvas-open').click(function () {
  //                 if (offCanvasSection.hasClass('show-nav')) {
  //                     // $('body').css('overflow', 'visible');
  //                     Drupal.translateX(mainSection, 0);
  //                     offCanvasSection.removeClass('show-nav');
  //                 } else {
  //
  //                     $('body,html').animate({
  //                         scrollTop: 0
  //                     }, 0);
  //
  //                     // $('body').css('overflow', 'hidden');
  //                     Drupal.translateX(mainSection, '-' + $(window).width() + 'px');
  //                     offCanvasSection.addClass('show-nav');
  //                 }
  //             });
  //
  //             Drupal.offCanvasWindowResize();
  //             Drupal.menuSectionResize();
  //
  //             // phone height resize fix
  //             var w = $(window).width();
  //             $(window).resize(function () {
  //                 if (w != $(window).width()) {
  //                     w = $(window).width();
  //                     if (offCanvasSection.hasClass('show-nav')) {
  //                         Drupal.translateX(mainSection, 0);
  //                         offCanvasSection.removeClass('show-nav');
  //                     }
  //                     Drupal.offCanvasWindowResize();
  //                     Drupal.menuSectionResize();
  //                 }
  //             });
  //         });
  //     }
  // };

  Drupal.behaviors.faqCollapser = {
    attach: function (context, settings) {
      $('.ds-col2grid .col2grid-bottom .field-name-field-page-faq .field-items .field-item h2', context)
        .once('faqCollapser', function () {
          $(this).click(function () {
            var content = $(this).parent();
            if (content.hasClass('active')) {
              content.removeClass('active');
            } else {
              content.addClass('active');
            }
          });
        });
    }
  };

  Drupal.pionierTeaserWidthResize = function () {
    $('.node-pionier.node-teaser, .node-medienmitteilung.view-mode-frontpage_teaser')
      .each(function () {
        $(this).find('.group-left').width($(this).width());
      });
  };

  Drupal.behaviors.pionierTeaserWidth = {
    attach: function (context, settings) {
      $('.node-pionier.node-teaser, .node-medienmitteilung.view-mode-frontpage_teaser', context)
        .once('pionierTeaserWidth', function () {
          Drupal.pionierTeaserWidthResize();
          $(window).resize(function () {
            Drupal.pionierTeaserWidthResize();
          });
        });
    }
  };

  Drupal.behaviors.medienmitteilungCaption = {
    attach: function (context, settings) {
      $('.node-medienmitteilung.view-mode-full .field-name-field-medienmitteilung-image').each(function () {
        var title = $(this).find('img').attr('title');
        if (title != undefined) {
          $(this).find('img').parent().append($('<span class="caption">' + title + '</span>'));

        }
      });
    }
  };

  Drupal.behaviors.backToTopAnimation = {
    attach: function (context, settings) {
      $('#back-top a', context).once('backToTopAnimation', function () {
        $(this).on("click",function (e) {
          e.preventDefault();
          $('body').animate({
            scrollTop: 0
          }, 1000);
        });
      });
    }
  }

  Drupal.pioniereGallerieForegroundResize = function (gallerieSelector) {
    var foreground = gallerieSelector.find('ul li.featured-img .featured-img-foreground');
    var img = gallerieSelector.find('ul li.featured-img img');
    img.imagesLoaded(function () {
      foreground.css('height', img.height() + 'px');
      foreground.css('width', img.width() + 'px');
    });
  };

  Drupal.behaviors.flexslider = {
    attach: function (context, settings) {
      $('.flexslider', context).once('flexslider', function () {
        var selector = $(this);

        selector.flexslider({
          animation: "slide",
          slideshow: "true",
          pauseOnHover: "true",
          touch: "true",
          customDirectionNav: $(".nav a")
        });

        if ($('html.no-touch ').length > 0) {
          selector.find('img').bind('click', function () {
            selector.flexslider('next');
          });
        }

        selector.imagesLoaded(function () {
          var imgHeight = selector.find('div.field-name-field-pg-slide-image img').height();
          selector.find('.nav a').css({"top": (imgHeight / 2) + 'px'});

          $(window).resize(function (e) {
            var imgHeight = selector.find('div.field-name-field-pg-slide-image img').height();
            selector.find('.nav a').css({"top": (imgHeight / 2) + 'px'});
          });
        });

      });
    }
  };

  Drupal.isScrolling = function (gap) {
    if (($(window).height() + gap) < $(document).height()) {
      return true;
    }
    return false;
  };

  Drupal.hideBackToTop = function (selector) {
    $('img').imagesLoaded(function () {
      if (Drupal.isScrolling(0)) {
        selector.css('visibility', 'visible');
      } else {
        selector.css('visibility', 'hidden');
      }
    });
  }

  Drupal.behaviors.backToTop = {
    attach: function (context, settings) {
      $('#back-top', context).once('backToTop', function () {
        var selector = $(this);
        Drupal.hideBackToTop(selector);
        $(window).resize(function () {
          Drupal.hideBackToTop(selector);
        });
      });
    }
  }

  Drupal.behaviors.onSettingImpulseTeaserBackgroundImage = {
    attach: function (context, settings) {
      $('.node-impuls.view-mode-teaser .field-name-field-impuls-backgroundimage', context).once('onSettingImpulseTeaserBackgroundImage', function () {
        var imageUrl = $(this).find('img').attr('src');
        $(this).css('background-image', "url('" + imageUrl + "')");
      });
    }
  };

  Drupal.repositionFooter = function () {
    if (Foundation.utils.is_small_only()) {
      $('#footer .region-footer-3').prepend($('#block-menu-menu-footer-menu'));
    } else {
      $('#footer .region-footer-1').prepend($('#block-menu-menu-footer-menu'));
    }
  };

  Drupal.behaviors.onMobileRespositionFooter = {
    attach: function (context, settings) {
      $('#footer', context).once('onMobileRespositionFooter', function () {
        Drupal.repositionFooter();
        $(window).resize(function () {
          Drupal.repositionFooter();
        });
      });
    }
  };

  Drupal.behaviors.togglePersonDescription = {
    attach: function (context, settings) {
      $('.view-team-overview', context).once('togglePersonDescription', function () {
        var btn_more = "more";
        var btn_less = "less"
        var url = $(location).attr('href').split('/')[3];
        switch (url) {
          case "de":
            btn_more = "mehr";
            btn_less = "weniger";
            break;
          case "fr":
            btn_more = "plus";
            btn_less = "moins";
            break;
          case "it":
            btn_more = "più";
            btn_less = "meno";
            break;
        }
        var fields = document.getElementsByClassName('views-field-field-beschreibung');
        var nodeId = [];
        for (var i = 0; i<fields.length; i++) {
          var nodeIds = fields[i].className.split(' ');
          nodeId.push(nodeIds[nodeIds.length-1]);
        }
        $.each(nodeId, function(i, v) { // New scope for i and v
          $("."+v).hide();
          $(".person_btn-"+v).html("<a>" + btn_more +"</a>")
          $(".person_btn-"+v).bind('click', function(e) {
            if ($("."+v).is(":hidden")) {
              $("."+v).show();
              $(".person_btn-"+v).html("<a>" + btn_less + "</a>");
            }
            else {
              $("."+v).hide();
              $(".person_btn-"+v).html("<a>" + btn_more +"</a>");
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.toggleTopic = {
    attach: function (context, settings) {
      $('.view-Pinoiere', context).once('toggleTopic', function () {
        var btn_more = "to the projects";
        var btn_less = "less";
        var url = $(location).attr('href').split('/')[3];
        switch (url) {
          case "de":
            btn_more = "zu den Projekten";
            btn_less = "weniger";
            break;
          case "fr":
            btn_more = "sur les projets";
            btn_less = "moins";
            break;
          case "it":
            btn_more = "sui progetti";
            btn_less = "meno";
            break;
        }
        $(this).find(".node-thema").each(function(i, obj) {
          var container = $(this).find(".ds-region_bottom");
          var btn_container = $(this).find(".thema-region_bottom");
          var topic = $(this);
          topic.addClass("thema-hide");
          btn_container.append("<a class='toggle-topic'>" + btn_more + "</a>");
          btn_container.find(".toggle-topic").bind('click', function(e) {
            if (container.is(":hidden")) {
              topic.addClass("thema-display");
              topic.removeClass("thema-hide");
              btn_container.find(".toggle-topic").text(btn_less);
            }
            else {
              topic.addClass("thema-hide");
              topic.removeClass("thema-display");
              btn_container.find(".toggle-topic").text(btn_more);
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.resetProjectFilter = {
    attach: function (context, settings) {
      $('.view-project-progress .views-exposed-widgets .views-exposed-widget').once('resetProjectFilter').each(this.attachWidgetJs);
    },
    attachWidgetJs: function (idx, column) {
      var checkboxes = $(this).find('.bef-checkboxes').children();
      if (checkboxes.length) {
        var firstchild = checkboxes.first().find('input');
        firstchild.bind('click', function (e) {
          checkboxes.each(function (i, obj) {
            if (i !== 0) {
              if ($(this).find('input').is(':checked')) {
                $(this).find('input').prop("checked", false);
              }
            }
          });
        });
        checkboxes.each(function (i, obj) {
          if (i !== 0) {
            $(this).bind('click', function (e) {
              if (firstchild.is(':checked')) {
                firstchild.prop("checked", false);
              }
            });
          }
        });
      }
    }
  };

  return Drupal

}(jQuery, this, document, Drupal, Lightbox);
